import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import { PropertyGrid } from "../components/Property/PropertyGrid"
import { HeroImage } from "../components/Hero/HeroImage"
import { Icon } from "../components/IconFactory/Icons"
import {
  CurveCarveTop,
  CurveCarveBottom,
  CurveBulgeBottom,
} from "../components/Curves/Curve"
import { Container, Row, Col, Button } from "react-bootstrap"
import { HR } from "../components/Typography/Seperators"

import { FeatureImage } from "../components/Feature/FeatureImage"
import { AccordionCollection } from "../components/Accordion/AccordionCollection"
import { Newsletter } from "../components/Misc/Newsletter"
import { ArticleSnippetHome } from "../components/Article/ArticleSnippetHome"
import { GuideTile } from "../components/Guides/GuideTiles"
import { CallMeBack } from "../components/Misc/CallMeBack"
import { truncate } from "../utils/truncate"
const HubArea = ({ data: { contentfulHubArea: area, guide } }) => {
  return (
    <Layout>
      <SEO
        title={area.seoTitle || area.title}
        description={area.summary && truncate(area.summary.summary, 350, true)}
      />
      <HeroImage
        fluid={area.heroPhoto && area.heroPhoto.fluid}
        variant={area.key}
        curveBottom={<CurveCarveTop variant="primary" />}
      >
        <Icon name="logo" />
        <span>{area.subtitle}</span>
        <h1>{area.title}</h1>
      </HeroImage>

      <div className="bg-primary text-white text-center py-5">
        <Container>
          <Row>
            <Col md={8} className="mx-auto">
              <h2>{area.headline}</h2>
              <HR />
              {area.summary && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: area.summary.childMarkdownRemark.html,
                  }}
                />
              )}
              <CallMeBack />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="primary" />

      <div>
        <Container className="py-5 text-center">
          <Row>
            <Col>
              <h2>{area.fundamentalsTitle ? area.fundamentalsTitle : area.subtitle + " Market"}</h2>
            </Col>
          </Row>
          <Row>
            {area.fundamentals &&
              area.fundamentals.map(feature => {
                return <FeatureImage key={feature.id} feature={feature} />
              })}
          </Row>
        </Container>
      </div>

      <CurveCarveTop variant="light" />
      <div className="bg-light py-5">
        <Container className="pb-5 text-center">
          <Row>
            <Col>
              <h2>
                {area.title.replace("Investments", "Investment")} Opportunities
              </h2>
            </Col>
          </Row>
        </Container>
        <PropertyGrid properties={area.property} />

        <Container>
          <Row>
            <Col className="text-center">
              <Button
                as={Link}
                variant="secondary"
                size="lg"
                to="/search-result"
              >
                Search Investments
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <CurveCarveTop variant="primary" background="light" />

      <AccordionCollection
        items={area.accordions}
        staffCtaImage={area.staffCtaImage}
      />

      <CurveCarveTop variant="light" background="primary" />

      <div className="bg-light pt-5">
        {(area.guidance || guide) && (
          <>
            <Container>
              <Row className="text-center pb-5">
                <Col>
                  <h2>{area.subtitle} - Guidance &amp; Tips</h2>
                </Col>
              </Row>

              <Row>
                {area.guidance.map(article => {
                  return (
                    <Col sm={6} lg={guide ? 3 : 4} key={article.id} className="mx-auto">
                      <ArticleSnippetHome
                        triangle
                        article={article}
                        variant={area.key}
                      />
                    </Col>
                  )
                })}
                {guide && (
                <Col sm={6} lg={3} className="mx-auto">
                  <GuideTile guide={guide} />
                </Col>
              )}
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    as={Link}
                    variant="secondary"
                    size="sm"
                    to={`/news/${area.key}`}
                  >
                    More {area.key} Articles
                  </Button>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
      <CurveBulgeBottom variant="light" />

      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query HubAreaDetailQuery($id: String) {
    contentfulHubArea(id: { eq: $id }) {
      ...HubAreaFragment
      property {
        ...PropertyListFragment
      }
    }
    guide: contentfulGuide(area: { id: { eq: $id } }) {
      ...GuideFragment
    }
  }
`

export default HubArea
